var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import TextField from '../../components/FormComponents/TextField';
import CloseIcon from '@mui/icons-material/Close';
import AutoCompleteDD from '../../components/FormComponents/AutoCompleteDD';
import './../../assets/scss/adminview.scss';
import ConfirmationAndInformationPoup, { POPUP_TYPE } from '../../components/UtilityComponents/ConfirmationAndInformationPoup';
import { api_service } from '../../api/api_service';
import { api_urls } from '../../api/api_urls';
import { utility } from '../../utils';
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
var initialProgramInfo = {
    Name: '',
    ProgramAspireOId: '',
    Code: '',
    Description: '',
    FinanceCompanyRecordId: '',
    PrivateLabel: '',
    TransactionCode: '',
    InvoiceCode: '',
    InvoiceLeadDays: '',
    DelinquencyCode: '',
    Country: ''
};
var ProgramManagementDrawer = function (_a) {
    var open = _a.open, handleClose = _a.handleClose, rowData = _a.rowData, userAction = _a.userAction, getProgramList = _a.getProgramList;
    var _b = React.useState(__assign({}, initialProgramInfo)), programInfo = _b[0], setProgramInfo = _b[1];
    var _c = React.useState(__assign({}, initialProgramInfo)), errors = _c[0], setErrors = _c[1];
    var _d = React.useState(''), actionType = _d[0], setActionType = _d[1];
    var _e = useState(false), openConfirmationPopup = _e[0], setOpenConfirmationPopup = _e[1];
    var _f = useState(false), openSuccessPopup = _f[0], setOpenSuccessPopup = _f[1];
    var _g = useState([]), dealerList = _g[0], setDealerList = _g[1];
    var _h = useState([]), rolesList = _h[0], setRolesList = _h[1];
    var _j = React.useState(false), isSyncing = _j[0], setIsSyncing = _j[1];
    var _k = React.useState([]), descriptionData = _k[0], setDescriptionData = _k[1];
    var _l = React.useState([]), descriptionOptions = _l[0], setDescriptionOptions = _l[1];
    var handleProgramInfoChange = function (e) {
        var _a = e.target, name = _a.name, value = _a.value;
        setErrors(function (prevState) {
            var _a;
            return (__assign(__assign({}, prevState), (_a = {}, _a[name] = '', _a)));
        });
        setProgramInfo(function (prevState) {
            var _a;
            return (__assign(__assign({}, prevState), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleOnInputBlur = function (e) {
        var _a = e.target, name = _a.name, value = _a.value;
        if (name === 'Description') {
            var _descriptionMatchedData_1 = descriptionData === null || descriptionData === void 0 ? void 0 : descriptionData.find(function (descObj) { return (descObj === null || descObj === void 0 ? void 0 : descObj.Description) == value; });
            setProgramInfo(function (prevState) { return (__assign(__assign({}, prevState), _descriptionMatchedData_1)); });
        }
        var updatedErrors = __assign({}, errors);
        updatedErrors[name] = validateField(name, value);
        setErrors(updatedErrors);
    };
    var validateField = function (name, value) {
        if (typeof (value) === 'string' && !value.trim()) {
            return 'This field is required.';
        }
        return '';
    };
    var isValidData = function () {
        var updatedErrors = __assign({}, errors);
        console.log('isValidData ~ programInfo:', programInfo);
        for (var key in programInfo) {
            var error = validateField(key, programInfo[key]);
            updatedErrors[key] = error;
        }
        setErrors(updatedErrors);
        var hasErrors = Object.values(updatedErrors).some(function (val) { return val !== ''; });
        return !hasErrors;
    };
    var handleClick = function () {
        if (actionType === 'View') {
            setActionType('Edit');
        }
        else if (actionType === 'Edit' || actionType === 'Create') {
            if (isValidData()) {
                setOpenConfirmationPopup(true);
            }
        }
        else {
            return;
        }
    };
    var handleDrawerClose = function () {
        setActionType('');
        setProgramInfo(__assign({}, initialProgramInfo));
        setErrors(__assign({}, initialProgramInfo));
        handleClose();
        setErrors(__assign({}, initialProgramInfo));
    };
    var handleUpdateProgram = function () {
        var __payload = {
            ProgramId: Number(programInfo === null || programInfo === void 0 ? void 0 : programInfo.Id),
            PrivateLabel: programInfo === null || programInfo === void 0 ? void 0 : programInfo.PrivateLabel
        };
        api_service.put({
            endPoint: api_urls.UPDATE_PROGRAM,
            payLoad: __payload,
            showLoader: true
        }).then(function (res) {
            var _a;
            if ((res === null || res === void 0 ? void 0 : res.data) && res.data.statusCode === 200) {
                setOpenSuccessPopup(true);
            }
            else {
                utility.alert((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.message);
            }
        }).catch(function (err) {
            console.log('getDealersList ~ err:', err);
        });
    };
    var handleCreateProgram = function () {
        var __payload = __assign({}, programInfo);
        api_service.post({
            endPoint: api_urls.CREATE_PROGRAM,
            payLoad: __payload,
            showLoader: true
        }).then(function (res) {
            var _a;
            if ((res === null || res === void 0 ? void 0 : res.data) && res.data.statusCode === 200) {
                setOpenSuccessPopup(true);
            }
            else {
                utility.alert((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.message);
            }
        }).catch(function (err) {
            console.log('getDealersList ~ err:', err);
        });
    };
    var getButtonText = function () {
        if (userAction === 'Create') {
            return 'Add';
        }
        else {
            return actionType === 'View' ? 'Edit' : 'Save';
        }
    };
    var getSelectedStateObject = useCallback(function (dropDownCode) {
        if (dropDownCode) {
            var Obj = [{ label: 'USA', code: 'USA' }, { label: 'Canada', code: 'CAN' }].find(function (countryobj) { return countryobj.code === dropDownCode; });
            return Obj;
        }
        return dropDownCode;
    }, [programInfo.Country]);
    var getSyncedData = function () {
        setIsSyncing(true);
        api_service.get({
            endPoint: api_urls.SYNC_PROGRAM,
            payLoad: {
                ProgramId: Number(rowData === null || rowData === void 0 ? void 0 : rowData.Id)
            }
        }).then((function (res) {
            var _a;
            setIsSyncing(false);
            if (res.data && res.data.statusCode === 200) {
                var response_1 = (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.data;
                if (response_1) {
                    setProgramInfo(function (prevState) { return (__assign(__assign({}, prevState), response_1)); });
                }
            }
            else {
                console.log('res error', res.data);
            }
        })).catch(function (error) {
            setIsSyncing(false);
            console.log('Error:', error);
        });
    };
    var getDecriptionOptions = function () {
        api_service.get({
            endPoint: api_urls.GET_DESCRIPTION_OPTIONS,
            payLoad: {
                Description: rowData === null || rowData === void 0 ? void 0 : rowData.ProgramId
            },
            showLoader: true
        }).then((function (res) {
            var _a;
            if (res.data && res.data.statusCode === 200) {
                var response = (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.data;
                if (response) {
                    setDescriptionData(__spreadArray([], response, true));
                    var __descriptionOptions = response === null || response === void 0 ? void 0 : response.map(function (descObj) {
                        return { label: descObj.Description, value: descObj.Description };
                    });
                    setDescriptionOptions(__descriptionOptions);
                }
            }
            else {
                console.log('res error', res.data);
            }
        })).catch(function (error) {
            console.log('Error:', error);
        });
    };
    var getSelectedDescObj = useCallback(function (dropdownValue) {
        if (dropdownValue) {
            var _descObj = descriptionOptions === null || descriptionOptions === void 0 ? void 0 : descriptionOptions.find(function (descObj) { return descObj.value == dropdownValue; });
            return _descObj;
        }
        return dropdownValue;
    }, [descriptionOptions, programInfo.Description]);
    useEffect(function () {
        setActionType(userAction);
    }, [userAction]);
    useEffect(function () {
        if (actionType === 'Create') {
            setProgramInfo(__assign({}, initialProgramInfo));
            getDecriptionOptions();
        }
        else {
            setProgramInfo(__assign({}, rowData));
            if (actionType === 'Edit') {
                getSyncedData();
            }
        }
    }, [actionType]);
    return (React.createElement(React.Fragment, null,
        React.createElement(ConfirmationAndInformationPoup, { popupType: POPUP_TYPE.UNSAVED_DATA_WARNING, titleText: 'Are you sure?', message: "Are you sure you want to ".concat(userAction !== 'Create' ? 'update' : 'create', " the Program?"), acceptBtnText: 'Yes', denyBtnText: 'No', acceptCallBack: useCallback(function () {
                if (userAction !== 'Create') {
                    handleUpdateProgram();
                }
                else {
                    handleCreateProgram();
                }
                setOpenConfirmationPopup(false);
            }, [openConfirmationPopup]), denyCallBack: useCallback(function () {
                setOpenConfirmationPopup(false);
            }, []), open: openConfirmationPopup, key: "".concat(openConfirmationPopup), cancelCallBack: useCallback(function () {
                setOpenConfirmationPopup(false);
            }, []) }),
        React.createElement(ConfirmationAndInformationPoup, { popupType: POPUP_TYPE.SUCCESS_ALERT, titleText: 'Success!', message: "Program has been ".concat(userAction !== 'Create' ? 'updated' : 'created', " successfully."), acceptBtnText: 'Ok', acceptCallBack: useCallback(function () {
                setOpenSuccessPopup(false);
                handleDrawerClose();
                getProgramList();
            }, [openConfirmationPopup]), open: openSuccessPopup, key: "".concat(openSuccessPopup) }),
        React.createElement(Dialog, { open: open, className: 'MuiDialogue-upload location-popup' },
            React.createElement(DialogTitle, { sx: { m: 0, p: 2 }, id: "customized-dialog-title" }, actionType === 'View' ? 'Program' : "".concat(actionType, " Program")),
            React.createElement(IconButton, { "aria-label": "close", onClick: function () { return handleDrawerClose(); }, className: 'popup-close', sx: {
                    position: 'absolute',
                    right: 8,
                    top: 8,
                } },
                React.createElement(CloseIcon, null)),
            React.createElement(DialogContent, { sx: { paddingTop: actionType === 'Edit' ? '0px' : '20px' } },
                actionType === 'Edit' &&
                    React.createElement(Box, { bgcolor: '#0e0e1333', borderRadius: '4px', padding: '4px 8px', marginBottom: '8px' },
                        React.createElement(Box, { sx: { cursor: 'pointer' }, display: 'flex', alignItems: 'center', justifyContent: isSyncing ? 'center' : 'flex-end', onClick: getSyncedData },
                            React.createElement(IconButton, { sx: { padding: '0px' } },
                                React.createElement(SyncOutlinedIcon, null)),
                            React.createElement(Typography, { padding: '0px 4px', fontSize: '14px' }, isSyncing ? 'Currently syncing the latest details from Aspire' : 'Sync again'))),
                actionType === 'View' ?
                    React.createElement(Grid, { container: true, rowSpacing: 3, columnSpacing: { xs: 1, sm: 2, md: 0 } },
                        React.createElement(Grid, { item: true, xs: 12, style: { borderBottom: '1px #E6E6E6 solid' } },
                            React.createElement(Grid, { style: { padding: '5px', borderBottom: 'light grey' } },
                                React.createElement(Typography, { className: 'view-page-labels' }, "Name (visible only on the portal)"),
                                React.createElement(Typography, { className: 'view-page-values' }, rowData === null || rowData === void 0 ? void 0 : rowData.Name))),
                        React.createElement(Grid, { item: true, xs: 12, style: { borderBottom: '1px #E6E6E6 solid' } },
                            React.createElement(Grid, { style: { padding: '5px', borderBottom: 'light grey' } },
                                React.createElement(Typography, { className: 'view-page-labels' }, "Description"),
                                React.createElement(Typography, { className: 'view-page-values' }, rowData === null || rowData === void 0 ? void 0 : rowData.Description))),
                        React.createElement(Grid, { item: true, xs: 12, style: { borderBottom: '1px #E6E6E6 solid' } },
                            React.createElement(Grid, { style: { padding: '5px', borderBottom: 'light grey' } },
                                React.createElement(Typography, { className: 'view-page-labels' }, "Code"),
                                React.createElement(Typography, { className: 'view-page-values' }, rowData === null || rowData === void 0 ? void 0 : rowData.Code))),
                        React.createElement(Grid, { item: true, xs: 12, style: { borderBottom: '1px #E6E6E6 solid' } },
                            React.createElement(Grid, { style: { padding: '5px', borderBottom: 'light grey' } },
                                React.createElement(Typography, { className: 'view-page-labels' }, "Program Aspire OId"),
                                React.createElement(Typography, { className: 'view-page-values' }, rowData === null || rowData === void 0 ? void 0 : rowData.ProgramAspireOId))),
                        React.createElement(Grid, { item: true, xs: 12, style: { borderBottom: '1px #E6E6E6 solid' } },
                            React.createElement(Grid, { style: { padding: '5px', borderBottom: 'light grey' } },
                                React.createElement(Typography, { className: 'view-page-labels' }, "Company ID"),
                                React.createElement(Typography, { className: 'view-page-values' }, rowData === null || rowData === void 0 ? void 0 : rowData.FinanceCompanyRecordId))),
                        React.createElement(Grid, { item: true, xs: 12, style: { borderBottom: '1px #E6E6E6 solid' } },
                            React.createElement(Grid, { style: { padding: '5px', borderBottom: 'light grey' } },
                                React.createElement(Typography, { className: 'view-page-labels' }, "Private Label"),
                                React.createElement(Typography, { className: 'view-page-values' }, rowData === null || rowData === void 0 ? void 0 : rowData.PrivateLabel))),
                        React.createElement(Grid, { item: true, xs: 12, style: { borderBottom: '1px #E6E6E6 solid' } },
                            React.createElement(Grid, { style: { padding: '5px', borderBottom: 'light grey' } },
                                React.createElement(Typography, { className: 'view-page-labels' }, "Transaction Code"),
                                React.createElement(Typography, { className: 'view-page-values' }, rowData === null || rowData === void 0 ? void 0 : rowData.TransactionCode))),
                        React.createElement(Grid, { item: true, xs: 12, style: { borderBottom: '1px #E6E6E6 solid' } },
                            React.createElement(Grid, { style: { padding: '5px', borderBottom: 'light grey' } },
                                React.createElement(Typography, { className: 'view-page-labels' }, "Invoice Code"),
                                React.createElement(Typography, { className: 'view-page-values' }, rowData === null || rowData === void 0 ? void 0 : rowData.InvoiceCode))),
                        React.createElement(Grid, { item: true, xs: 12, style: { borderBottom: '1px #E6E6E6 solid' } },
                            React.createElement(Grid, { style: { padding: '5px', borderBottom: 'light grey' } },
                                React.createElement(Typography, { className: 'view-page-labels' }, "Invoice Lead Days"),
                                React.createElement(Typography, { className: 'view-page-values' }, rowData === null || rowData === void 0 ? void 0 : rowData.InvoiceLeadDays))),
                        React.createElement(Grid, { item: true, xs: 12, style: { borderBottom: '1px #E6E6E6 solid' } },
                            React.createElement(Grid, { style: { padding: '5px', borderBottom: 'light grey' } },
                                React.createElement(Typography, { className: 'view-page-labels' }, "Delinquency Code"),
                                React.createElement(Typography, { className: 'view-page-values' }, rowData === null || rowData === void 0 ? void 0 : rowData.DelinquencyCode))),
                        React.createElement(Grid, { item: true, xs: 12, style: { borderBottom: '1px #E6E6E6 solid' } },
                            React.createElement(Grid, { style: { padding: '5px', borderBottom: 'light grey' } },
                                React.createElement(Typography, { className: 'view-page-labels' }, "Country"),
                                React.createElement(Typography, { className: 'view-page-values' }, rowData === null || rowData === void 0 ? void 0 : rowData.Country))))
                    :
                        React.createElement(Grid, { container: true, rowSpacing: 3, columnSpacing: { xs: 1, sm: 2, md: 3 } },
                            React.createElement(Grid, { item: true, xs: 12 },
                                React.createElement(TextField, { label: 'Name (visible only on the portal)', name: 'Name', isMandatoryField: true, disabled: false, placeholder: 'Name', fullWidth: true, value: programInfo === null || programInfo === void 0 ? void 0 : programInfo.Name, onChange: handleProgramInfoChange, onBlur: handleOnInputBlur, error: Boolean(errors.Name), error_message: errors.Name, type: "text" })),
                            actionType === 'Create' ?
                                React.createElement(Grid, { item: true, xs: 12 },
                                    React.createElement(AutoCompleteDD, { label: 'Description', isMandatoryField: true, errorMessage: errors.Description, options: descriptionOptions, displayKey: 'label', valueKey: 'value', placeholder: 'Start Typing...', handleChange: handleProgramInfoChange, selectedValue: getSelectedDescObj(programInfo === null || programInfo === void 0 ? void 0 : programInfo.Description), name: "Description", handleBlur: handleOnInputBlur }))
                                :
                                    React.createElement(Grid, { item: true, xs: 12 },
                                        React.createElement(TextField, { label: 'Description', name: 'Description', isMandatoryField: true, disabled: actionType !== 'Create', placeholder: 'Description', fullWidth: true, value: programInfo === null || programInfo === void 0 ? void 0 : programInfo.Description, onChange: handleProgramInfoChange, onBlur: handleOnInputBlur, error: Boolean(errors.Description), error_message: errors.Description, type: "text" })),
                            React.createElement(Grid, { item: true, xs: 12 },
                                React.createElement(TextField, { label: 'Code', name: 'Code', isMandatoryField: true, disabled: true, placeholder: 'Code', fullWidth: true, value: programInfo === null || programInfo === void 0 ? void 0 : programInfo.Code, onChange: handleProgramInfoChange, onBlur: handleOnInputBlur, error: Boolean(errors.Code), error_message: errors.Code, type: "text" })),
                            React.createElement(Grid, { item: true, xs: 12 },
                                React.createElement(TextField, { label: 'ProgramAspireOId', name: 'ProgramAspireOId', isMandatoryField: true, disabled: true, placeholder: 'ProgramAspireOId', fullWidth: true, value: programInfo === null || programInfo === void 0 ? void 0 : programInfo.ProgramAspireOId, onChange: handleProgramInfoChange, onBlur: handleOnInputBlur, error: Boolean(errors.ProgramAspireOId), error_message: errors.ProgramAspireOId, type: "text" })),
                            React.createElement(Grid, { item: true, xs: 12 },
                                React.createElement(TextField, { label: 'Company ID', name: 'FinanceCompanyRecordId', isMandatoryField: true, disabled: true, placeholder: 'Company ID', fullWidth: true, value: programInfo === null || programInfo === void 0 ? void 0 : programInfo.FinanceCompanyRecordId, onChange: handleProgramInfoChange, onBlur: handleOnInputBlur, error: Boolean(errors.FinanceCompanyRecordId), error_message: errors.FinanceCompanyRecordId, type: "text" })),
                            React.createElement(Grid, { item: true, xs: 12 },
                                React.createElement(TextField, { label: 'Private Label', name: 'PrivateLabel', isMandatoryField: true, placeholder: 'Private Label', fullWidth: true, disabled: true, value: programInfo === null || programInfo === void 0 ? void 0 : programInfo.PrivateLabel, onChange: handleProgramInfoChange, onBlur: handleOnInputBlur, error: Boolean(errors.PrivateLabel), error_message: errors.PrivateLabel, type: "text" })),
                            React.createElement(Grid, { item: true, xs: 12 },
                                React.createElement(TextField, { label: 'Transaction Code', name: 'TransactionCode', isMandatoryField: true, disabled: true, placeholder: 'Transaction Code', fullWidth: true, value: programInfo === null || programInfo === void 0 ? void 0 : programInfo.TransactionCode, onChange: handleProgramInfoChange, onBlur: handleOnInputBlur, error: Boolean(errors.TransactionCode), error_message: errors.TransactionCode, type: "text" })),
                            React.createElement(Grid, { item: true, xs: 12 },
                                React.createElement(TextField, { label: 'Invoice Code', name: 'InvoiceCode', isMandatoryField: true, disabled: true, placeholder: 'Invoice Code', fullWidth: true, value: programInfo === null || programInfo === void 0 ? void 0 : programInfo.InvoiceCode, onChange: handleProgramInfoChange, onBlur: handleOnInputBlur, error: Boolean(errors.InvoiceCode), error_message: errors.InvoiceCode, type: "text" })),
                            React.createElement(Grid, { item: true, xs: 12 },
                                React.createElement(TextField, { label: 'Invoice Lead Days', name: 'InvoiceLeadDays', isMandatoryField: true, disabled: true, placeholder: 'Invoice Lead Days', fullWidth: true, value: programInfo === null || programInfo === void 0 ? void 0 : programInfo.InvoiceLeadDays, onChange: handleProgramInfoChange, onBlur: handleOnInputBlur, error: Boolean(errors.InvoiceLeadDays), error_message: errors.InvoiceLeadDays, type: "text" })),
                            React.createElement(Grid, { item: true, xs: 12 },
                                React.createElement(TextField, { label: 'Delinquency Code', name: 'DelinquencyCode', isMandatoryField: true, disabled: true, placeholder: 'Delinquency Code', fullWidth: true, value: programInfo === null || programInfo === void 0 ? void 0 : programInfo.DelinquencyCode, onChange: handleProgramInfoChange, onBlur: handleOnInputBlur, error: Boolean(errors.DelinquencyCode), error_message: errors.DelinquencyCode, type: "text" })),
                            React.createElement(Grid, { item: true, xs: 12 },
                                React.createElement(AutoCompleteDD, { options: [{ label: 'USA', code: 'USA' }, { label: 'Canada', code: 'CAN' }], displayKey: 'label', valueKey: 'code', label: 'Country', placeholder: 'Select', handleChange: handleProgramInfoChange, selectedValue: getSelectedStateObject(programInfo.Country), name: "Country", errorMessage: errors.Country, isMandatoryField: true, disabled: userAction !== 'Create', handleBlur: handleOnInputBlur })))),
            React.createElement(DialogActions, null,
                React.createElement(Button, { variant: 'outlined', onClick: function () { return handleDrawerClose(); }, size: 'small', className: 'btn-txt' }, "Cancel"),
                React.createElement(Button, { variant: 'contained', onClick: function () { return handleClick(); }, size: 'small', className: 'btn-txt' }, getButtonText())))));
};
export default ProgramManagementDrawer;
