import React from 'react';
var DealerProgramAssociationIcon = function (_a) {
    var isDarkMode = _a.isDarkMode;
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "20", height: "20", viewBox: "0 0 20 20", fill: "none" },
        React.createElement("g", { "clip-path": "url(#clip0_13161_1039)" },
            React.createElement("path", { d: "M14.1667 17.5V15.8333C14.1667 14.9493 13.8155 14.1014 13.1904 13.4763C12.5652 12.8512 11.7174 12.5 10.8333 12.5H4.16666C3.28261 12.5 2.43476 12.8512 1.80964 13.4763C1.18452 14.1014 0.833328 14.9493 0.833328 15.8333V17.5", "stroke-width": "2", "stroke-linecap": "round", "stroke-linejoin": "round", stroke: isDarkMode ? "#FFFFFF" : "#868CA2" }),
            React.createElement("path", { d: "M7.50001 9.16667C9.34095 9.16667 10.8333 7.67428 10.8333 5.83333C10.8333 3.99238 9.34095 2.5 7.50001 2.5C5.65906 2.5 4.16667 3.99238 4.16667 5.83333C4.16667 7.67428 5.65906 9.16667 7.50001 9.16667Z", "stroke-width": "2", "stroke-linecap": "round", "stroke-linejoin": "round", stroke: isDarkMode ? "#FFFFFF" : "#868CA2" }),
            React.createElement("path", { d: "M19.1667 17.5001V15.8334C19.1661 15.0948 18.9203 14.3774 18.4678 13.7937C18.0153 13.2099 17.3818 12.793 16.6667 12.6084", "stroke-width": "2", "stroke-linecap": "round", "stroke-linejoin": "round", stroke: isDarkMode ? "#FFFFFF" : "#868CA2" }),
            React.createElement("path", { d: "M13.3333 2.6084C14.0503 2.79198 14.6859 3.20898 15.1397 3.79366C15.5935 4.37833 15.8399 5.09742 15.8399 5.83757C15.8399 6.57771 15.5935 7.2968 15.1397 7.88147C14.6859 8.46615 14.0503 8.88315 13.3333 9.06673", "stroke-width": "2", "stroke-linecap": "round", "stroke-linejoin": "round", stroke: isDarkMode ? "#FFFFFF" : "#868CA2" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0_13161_1039" },
                React.createElement("rect", { width: "20", height: "20", fill: isDarkMode ? "#FFFFFF" : "#868CA2" })))));
};
export default DealerProgramAssociationIcon;
