import * as React from 'react';
import { NavigationConfig } from '../utils/navigation_config';
import LoginSignup from '../pages/loginSignup';
import CheckAuth from '../core/authentication';
import Dashboard from '../pages/dashboard/Dashboard';
import Support from '../pages/support/Support';
import AdminManagement from '../pages/adminManagement/AdminManagement';
import DealerManagementCreate from '../pages/dealerManagement/dealerManagementCreate';
import UserManagement from '../pages/userManagement/UserManagement';
import DealerManagementView from '../pages/dealerManagement/DealerManagementView';
import DealerManagementEdit from '../pages/dealerManagement/DealerManagementEdit';
import DealerManagement from '../pages/dealerManagement/DealerManagement';
import MarketingContent from '../pages/marketingContent/MarketingContent';
import ResubmitApplication from '../pages/resubmitApplication/ResubmitApplication';
import DealerProgramAssocation from '../pages/dealerProgramAssocation/DealerProgramAssocation';
import ProgramManagement from '../pages/ProgramManagement/ProgramManagement';
var navConfig = NavigationConfig;
export var main_routes = [
    {
        path: '/*',
        element: React.createElement(LoginSignup, null)
    },
    {
        path: navConfig.DealerManagement,
        element: React.createElement(CheckAuth, null,
            React.createElement(DealerManagement, null))
    },
    {
        path: navConfig.DealerProgramAssocation,
        element: React.createElement(CheckAuth, null,
            React.createElement(DealerProgramAssocation, null))
    },
    {
        path: "".concat(navConfig.DealerManagement, "/create"),
        element: React.createElement(CheckAuth, null,
            React.createElement(DealerManagementCreate, null))
    },
    {
        path: "".concat(navConfig.DealerManagement, "/view/:id"),
        element: React.createElement(CheckAuth, null,
            React.createElement(DealerManagementView, null))
    },
    {
        path: "".concat(navConfig.DealerManagement, "/edit/:id"),
        element: React.createElement(CheckAuth, null,
            React.createElement(DealerManagementEdit, null))
    },
    {
        path: navConfig.UserManagement,
        element: React.createElement(CheckAuth, null,
            React.createElement(UserManagement, null))
    },
    {
        path: navConfig.Dashboard,
        element: React.createElement(CheckAuth, null,
            React.createElement(Dashboard, null))
    },
    {
        path: navConfig.AdminManagement,
        element: React.createElement(CheckAuth, null,
            React.createElement(AdminManagement, null))
    },
    {
        path: navConfig.ResubmitApplication,
        element: React.createElement(CheckAuth, null,
            React.createElement(ResubmitApplication, null))
    },
    {
        path: navConfig.Support,
        element: React.createElement(CheckAuth, null,
            React.createElement(Support, null))
    },
    {
        path: navConfig.MarketingConent,
        element: React.createElement(CheckAuth, null,
            React.createElement(MarketingContent, null))
    },
    {
        path: navConfig.ProgramManagement,
        element: React.createElement(CheckAuth, null,
            React.createElement(ProgramManagement, null))
    },
];
