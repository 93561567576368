export var DashBoardDataEnum;
(function (DashBoardDataEnum) {
    DashBoardDataEnum["Name"] = "Name";
    DashBoardDataEnum["CreatedTime"] = "CreatedTime";
    DashBoardDataEnum["TxRecordId"] = "TxRecordId";
    DashBoardDataEnum["Amount"] = "Amount";
})(DashBoardDataEnum || (DashBoardDataEnum = {}));
export var DashBoardDataColumns = [
    { label: 'Name', apiKey: DashBoardDataEnum.Name, hasSort: true, colDataType: 'string' },
    { label: 'Date and Time (EST)', apiKey: DashBoardDataEnum.CreatedTime, hasSort: true, colDataType: 'date' },
    { label: 'Transaction#', apiKey: DashBoardDataEnum.TxRecordId, hasSort: false, colDataType: 'string' },
    { label: 'Amount', apiKey: DashBoardDataEnum.Amount, hasSort: true, colDataType: 'number' },
];
export var UserMangementDataEnum;
(function (UserMangementDataEnum) {
    UserMangementDataEnum["Name"] = "FirstName";
    UserMangementDataEnum["Role"] = "RoleType";
    UserMangementDataEnum["EmailAddress"] = "Email";
    UserMangementDataEnum["PortalName"] = "PortalName";
    UserMangementDataEnum["CreatedTime"] = "CreatedTime";
    UserMangementDataEnum["Status"] = "userStatus";
})(UserMangementDataEnum || (UserMangementDataEnum = {}));
export var AdminMangementDataEnum;
(function (AdminMangementDataEnum) {
    AdminMangementDataEnum["Name"] = "FirstName";
    AdminMangementDataEnum["Role"] = "RoleDescription";
    AdminMangementDataEnum["EmailAddress"] = "Email";
    AdminMangementDataEnum["PhoneNumber"] = "PhoneNumber";
    AdminMangementDataEnum["CreatedTime"] = "CreatedTime";
})(AdminMangementDataEnum || (AdminMangementDataEnum = {}));
export var DealerProgramAssociation;
(function (DealerProgramAssociation) {
    DealerProgramAssociation["DealerName"] = "DealerName";
    DealerProgramAssociation["DealerOID"] = "DealerAccountAspireOId";
    DealerProgramAssociation["Programs"] = "ProgramNames";
})(DealerProgramAssociation || (DealerProgramAssociation = {}));
export var ResubmitApplicationDataEnum;
(function (ResubmitApplicationDataEnum) {
    ResubmitApplicationDataEnum["Name"] = "CustomerName";
    ResubmitApplicationDataEnum["PortalName"] = "PortalName";
    ResubmitApplicationDataEnum["SalesRepName"] = "SalesRepName";
    ResubmitApplicationDataEnum["DateAndTime"] = "CreatedTime";
    ResubmitApplicationDataEnum["ErrorMessage"] = "SyncErrorLog";
    ResubmitApplicationDataEnum["Status"] = "SyncStatus";
})(ResubmitApplicationDataEnum || (ResubmitApplicationDataEnum = {}));
export var DealerMangementDataEnum;
(function (DealerMangementDataEnum) {
    DealerMangementDataEnum["Name"] = "Name";
    DealerMangementDataEnum["PortalName"] = "Domain";
    DealerMangementDataEnum["CreatedTime"] = "CreatedTime";
})(DealerMangementDataEnum || (DealerMangementDataEnum = {}));
export var MarketingContentEnum;
(function (MarketingContentEnum) {
    MarketingContentEnum["Portal"] = "Portal";
    MarketingContentEnum["NotificationMessage"] = "Message";
    MarketingContentEnum["FromDate"] = "FromDate";
    MarketingContentEnum["FromTime"] = "FromTime";
    MarketingContentEnum["ToDate"] = "ToDate";
    MarketingContentEnum["ToTime"] = "ToTime";
    MarketingContentEnum["Status"] = "Status";
})(MarketingContentEnum || (MarketingContentEnum = {}));
export var ProgramManagementEnum;
(function (ProgramManagementEnum) {
    ProgramManagementEnum["ProgramName"] = "Name";
    ProgramManagementEnum["CompanyID"] = "FinanceCompanyRecordId";
    ProgramManagementEnum["Country"] = "Country";
    ProgramManagementEnum["PrivateLabel"] = "PrivateLabel";
})(ProgramManagementEnum || (ProgramManagementEnum = {}));
export var UserMangementCols = [
    { label: 'Name', apiKey: UserMangementDataEnum.Name, hasSort: false, colDataType: 'string' },
    { label: 'Role', apiKey: UserMangementDataEnum.Role, hasSort: false, colDataType: 'string' },
    { label: 'Email Address', apiKey: UserMangementDataEnum.EmailAddress, hasSort: false, colDataType: 'string' },
    { label: 'Portal Name', apiKey: UserMangementDataEnum.PortalName, hasSort: false, colDataType: 'string' },
    { label: 'Created Date', apiKey: UserMangementDataEnum.CreatedTime, hasSort: true, colDataType: 'date' },
    { label: 'Status', apiKey: UserMangementDataEnum.Status, hasSort: false, colDataType: 'string' },
];
export var AdminManagementCols = [
    { label: 'Admin Name', apiKey: AdminMangementDataEnum.Name, hasSort: false, colDataType: 'string' },
    { label: 'Role', apiKey: AdminMangementDataEnum.Role, hasSort: false, colDataType: 'string' },
    { label: 'Email Address', apiKey: AdminMangementDataEnum.EmailAddress, hasSort: false, colDataType: 'string' },
    { label: 'Phone Number', apiKey: AdminMangementDataEnum.PhoneNumber, hasSort: false, colDataType: 'string' },
    { label: 'Created Date', apiKey: AdminMangementDataEnum.CreatedTime, hasSort: true, colDataType: 'date' },
];
export var DealerProgramAssociationCols = [
    { label: 'Dealer Name', apiKey: DealerProgramAssociation.DealerName, hasSort: false, colDataType: 'string' },
    { label: 'Dealer OID', apiKey: DealerProgramAssociation.DealerOID, hasSort: false, colDataType: 'string' },
    { label: 'Programs', apiKey: DealerProgramAssociation.Programs, hasSort: false, colDataType: 'string' },
];
export var ResubmitApplicationCols = [
    { label: 'Customer Name', apiKey: ResubmitApplicationDataEnum.Name, hasSort: false, colDataType: 'string' },
    { label: 'Portal Name', apiKey: ResubmitApplicationDataEnum.PortalName, hasSort: false, colDataType: 'string' },
    { label: 'SalesRep Name', apiKey: ResubmitApplicationDataEnum.SalesRepName, hasSort: false, colDataType: 'string' },
    { label: 'Date and Time (EST)', apiKey: ResubmitApplicationDataEnum.DateAndTime, hasSort: true, colDataType: 'date' },
    { label: 'Error Message', apiKey: ResubmitApplicationDataEnum.ErrorMessage, hasSort: false, colDataType: 'string' },
    { label: 'Status', apiKey: ResubmitApplicationDataEnum.Status, hasSort: false, colDataType: 'string' },
];
export var DealerManagementCols = [
    { label: 'Portal Name', apiKey: DealerMangementDataEnum.Name, hasSort: false, colDataType: 'string' },
    { label: 'Portal URL', apiKey: DealerMangementDataEnum.PortalName, hasSort: false, colDataType: 'string' },
    { label: 'Created Date', apiKey: DealerMangementDataEnum.CreatedTime, hasSort: true, colDataType: 'date' },
];
export var SupportTicketDataEnum;
(function (SupportTicketDataEnum) {
    SupportTicketDataEnum["TicketId"] = "TicketCode";
    SupportTicketDataEnum["Description"] = "Description";
    SupportTicketDataEnum["RaisedBy"] = "TicketRaisedBy";
    SupportTicketDataEnum["CreatedTime"] = "CreatedTime";
    SupportTicketDataEnum["Status"] = "ticketStatus";
})(SupportTicketDataEnum || (SupportTicketDataEnum = {}));
export var SupportTicketCols = [
    { label: 'Ticket ID', apiKey: SupportTicketDataEnum.TicketId, hasSort: false, colDataType: 'string' },
    { label: 'Issue Description', apiKey: SupportTicketDataEnum.Description, hasSort: false, colDataType: 'string' },
    { label: 'Raised By', apiKey: SupportTicketDataEnum.RaisedBy, hasSort: false, colDataType: 'string' },
    { label: 'Created Date', apiKey: SupportTicketDataEnum.CreatedTime, hasSort: true, colDataType: 'date' },
    { label: 'Status', apiKey: SupportTicketDataEnum.Status, hasSort: false, colDataType: 'string' },
];
export var GenericMarketingTable = [
    { label: 'Notification Message', apiKey: MarketingContentEnum.NotificationMessage, hasSort: false, colDataType: 'string' },
    { label: 'From Date', apiKey: MarketingContentEnum.FromDate, hasSort: false, colDataType: 'date' },
    { label: 'From Time', apiKey: MarketingContentEnum.FromTime, hasSort: false, colDataType: 'date' },
    { label: 'To Date', apiKey: MarketingContentEnum.ToDate, hasSort: false, colDataType: 'date' },
    { label: 'To Time', apiKey: MarketingContentEnum.ToTime, hasSort: false, colDataType: 'date' },
    { label: 'Status', apiKey: MarketingContentEnum.Status, hasSort: false, colDataType: 'string' }
];
export var PortalMarketingTable = [
    { label: 'Portal', apiKey: MarketingContentEnum.Portal, hasSort: false, colDataType: 'string' },
    { label: 'Notification Message', apiKey: MarketingContentEnum.NotificationMessage, hasSort: false, colDataType: 'string' },
    { label: 'From Date', apiKey: MarketingContentEnum.FromDate, hasSort: false, colDataType: 'date' },
    { label: 'From Time', apiKey: MarketingContentEnum.FromTime, hasSort: false, colDataType: 'date' },
    { label: 'To Date', apiKey: MarketingContentEnum.ToDate, hasSort: false, colDataType: 'date' },
    { label: 'To Time', apiKey: MarketingContentEnum.ToTime, hasSort: false, colDataType: 'date' },
    { label: 'Status', apiKey: MarketingContentEnum.Status, hasSort: false, colDataType: 'string' }
];
export var ProgramManagementTable = [
    { label: 'Program Name', apiKey: ProgramManagementEnum.ProgramName, hasSort: false, colDataType: 'string' },
    { label: 'Company ID', apiKey: ProgramManagementEnum.CompanyID, hasSort: false, colDataType: 'string' },
    { label: 'Country', apiKey: ProgramManagementEnum.Country, hasSort: false, colDataType: 'string' },
    { label: 'Private Label', apiKey: ProgramManagementEnum.PrivateLabel, hasSort: false, colDataType: 'string' },
];
