var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import TextField from '../../components/FormComponents/TextField';
import CloseIcon from '@mui/icons-material/Close';
import './../../assets/scss/adminview.scss';
import ConfirmationAndInformationPoup, { POPUP_TYPE } from '../../components/UtilityComponents/ConfirmationAndInformationPoup';
import { api_service } from '../../api/api_service';
import { api_urls } from '../../api/api_urls';
import AutoCompleteMultiSelectDD from '../../components/FormComponents/AutoCompleteMulitSelectDD';
import { utility } from '../../utils';
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
var initialDealerProgramInfo = {
    DealerAccountAspireOId: '',
    DealerName: '',
    ProgramNames: ''
};
var DealerProgramAssocation = function (_a) {
    var _b, _c;
    var open = _a.open, handleClose = _a.handleClose, rowData = _a.rowData, userAction = _a.userAction, DealerProgramAssociationList = _a.DealerProgramAssociationList;
    var _d = React.useState(__assign({}, initialDealerProgramInfo)), dealerProgramInfo = _d[0], setDealerProgramInfo = _d[1];
    var _e = React.useState(__assign({}, initialDealerProgramInfo)), errors = _e[0], setErrors = _e[1];
    var _f = React.useState(''), actionType = _f[0], setActionType = _f[1];
    var _g = useState(false), openConfirmationPopup = _g[0], setOpenConfirmationPopup = _g[1];
    var _h = useState(false), openSuccessPopup = _h[0], setOpenSuccessPopup = _h[1];
    var _j = React.useState([]), programsList = _j[0], setProgramsList = _j[1];
    var _k = React.useState(false), isSyncing = _k[0], setIsSyncing = _k[1];
    var handleNewLocationInfoChange = function (e) {
        var _a = e.target, name = _a.name, value = _a.value;
        setErrors(function (prevState) {
            var _a;
            return (__assign(__assign({}, prevState), (_a = {}, _a[name] = '', _a)));
        });
        setDealerProgramInfo(function (prevState) {
            var _a;
            return (__assign(__assign({}, prevState), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleOnInputBlur = function (e) {
        var _a = e.target, name = _a.name, value = _a.value;
        if (name == 'DealerAccountAspireOId') {
            getDealerNameBasedOnOID(value);
        }
        var updatedErrors = __assign({}, errors);
        updatedErrors[name] = validateField(name, name === 'ProgramNames' ? dealerProgramInfo.ProgramNames : value);
        setErrors(updatedErrors);
    };
    var validateField = function (name, value) {
        var _a;
        if (name === 'ProgramNames') {
            if (!((_a = value[0]) === null || _a === void 0 ? void 0 : _a.label)) {
                return 'This field is required.';
            }
        }
        if (typeof (value) === 'string' && !value.trim() && name !== 'ProgramNames') {
            return 'This field is required.';
        }
        return '';
    };
    var isValidData = function () {
        var updatedErrors = __assign({}, errors);
        for (var key in dealerProgramInfo) {
            var error = validateField(key, dealerProgramInfo[key]);
            updatedErrors[key] = error;
        }
        setErrors(updatedErrors);
        var hasErrors = Object.values(updatedErrors).some(function (val) { return val !== ''; });
        return !hasErrors;
    };
    var handleClick = function () {
        if (actionType === 'View') {
            setActionType('Edit');
        }
        else if (actionType === 'Edit' || actionType === 'Create') {
            if (isValidData()) {
                setOpenConfirmationPopup(true);
            }
        }
        else {
            return;
        }
    };
    var handleDrawerClose = function () {
        setActionType('');
        setDealerProgramInfo(__assign({}, initialDealerProgramInfo));
        setErrors(__assign({}, initialDealerProgramInfo));
        handleClose();
        setErrors(__assign({}, initialDealerProgramInfo));
    };
    var getDealerNameBasedOnOID = function (value) {
        api_service.get({
            endPoint: api_urls.GET_DEALER_NAME,
            payLoad: {
                DealerAccountAspireOId: value
            }
        }).then(function (res) {
            var _a;
            if (((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.statusCode) === 200) {
                setDealerProgramInfo(function (prevState) { var _a, _b; return (__assign(__assign({}, prevState), { DealerName: (_b = (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.Name })); });
            }
        }).catch(function (err) {
            console.log('getDealerNameBasedOnOID ~ err:', err);
        });
    };
    var getProgramList = function () {
        api_service.get({
            endPoint: api_urls.PROGRAMS_LIST,
        }).then((function (res) {
            var _a;
            if (res.data && res.data.statusCode === 200) {
                var response = (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.data;
                if (response) {
                    var programOptions = response.map(function (programObj) { return ({
                        label: programObj.Name,
                        value: programObj.Id
                    }); });
                    setProgramsList(programOptions);
                }
            }
            else {
                console.log('res error', res.data);
            }
        })).catch(function (error) {
            console.log('Error:', error);
        });
    };
    var handleUpdateAdmin = function () {
        var __programIds = getPayloadProgramIds();
        var __payload = {
            DealerName: dealerProgramInfo.DealerName,
            DealerOId: dealerProgramInfo.DealerAccountAspireOId,
            ProgramIds: __programIds
        };
        api_service.put({
            endPoint: api_urls.CREATE_DEALER_PROG_ASSOCIATION,
            payLoad: __payload,
            showLoader: true
        }).then(function (res) {
            var _a;
            if ((res === null || res === void 0 ? void 0 : res.data) && res.data.statusCode === 200) {
                setOpenSuccessPopup(true);
            }
            else {
                utility.alert((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.message);
            }
        }).catch(function (err) {
            console.log('getDealersList ~ err:', err);
        });
    };
    var getPayloadProgramIds = function () {
        var __programNames = __spreadArray([], dealerProgramInfo.ProgramNames, true);
        var __programIds = __programNames.map(function (item) {
            return Number(item.value);
        });
        return __programIds;
    };
    var handleCreateDealerProgramAssociation = function () {
        var __programIds = getPayloadProgramIds();
        var __payload = {
            DealerName: dealerProgramInfo.DealerName,
            DealerOId: dealerProgramInfo.DealerAccountAspireOId,
            ProgramIds: __programIds
        };
        api_service.post({
            endPoint: api_urls.CREATE_DEALER_PROG_ASSOCIATION,
            payLoad: __payload,
            showLoader: true
        }).then(function (res) {
            var _a;
            if ((res === null || res === void 0 ? void 0 : res.data) && res.data.statusCode === 200) {
                setOpenSuccessPopup(true);
            }
            else {
                utility.alert((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.message);
            }
        }).catch(function (err) {
            console.log('handleCreateDealerProgramAssociation ~ err:', err);
        });
    };
    var getButtonText = function () {
        if (userAction === 'Create') {
            return 'Add';
        }
        else {
            return actionType === 'View' ? 'Edit' : 'Save';
        }
    };
    var handleSelectionChange = function (event, values) {
        setErrors(function (prevState) { return (__assign(__assign({}, prevState), { ProgramNames: '' })); });
        setDealerProgramInfo(function (prevState) { return (__assign(__assign({}, prevState), { ProgramNames: values })); });
    };
    var getSyncedData = function () {
        setIsSyncing(true);
        api_service.get({
            endPoint: api_urls.SYNC_DEALER_PROG_ASSOCIATION,
            payLoad: {
                DealerOId: rowData === null || rowData === void 0 ? void 0 : rowData.DealerAccountAspireOId
            }
        }).then((function (res) {
            var _a;
            setIsSyncing(false);
            if (res.data && res.data.statusCode === 200) {
                var response_1 = (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.data;
                if (response_1) {
                    setDealerProgramInfo(function (prevState) { return (__assign(__assign({}, prevState), { DealerName: response_1.DealerName })); });
                }
            }
            else {
                console.log('res error', res.data);
            }
        })).catch(function (error) {
            setIsSyncing(false);
            console.log('Error:', error);
        });
    };
    useEffect(function () {
        getProgramList();
    }, []);
    useEffect(function () {
        setActionType(userAction);
    }, [userAction]);
    useEffect(function () {
        var _a, _b;
        if (actionType === 'Create') {
            setDealerProgramInfo(__assign({}, initialDealerProgramInfo));
        }
        else {
            var __programIds = (rowData === null || rowData === void 0 ? void 0 : rowData.ProgramNames) && ((_b = (_a = rowData === null || rowData === void 0 ? void 0 : rowData.ProgramNames) === null || _a === void 0 ? void 0 : _a.split(',')) === null || _b === void 0 ? void 0 : _b.map(function (item) {
                var _selectedProgram = programsList === null || programsList === void 0 ? void 0 : programsList.find(function (program) { return program.label == item; });
                return { label: _selectedProgram.label, value: _selectedProgram.value };
            }));
            setDealerProgramInfo(__assign(__assign({}, rowData), { ProgramNames: __programIds }));
            if (actionType === 'Edit') {
                getSyncedData();
            }
        }
    }, [actionType]);
    return (React.createElement(React.Fragment, null,
        React.createElement(ConfirmationAndInformationPoup, { popupType: POPUP_TYPE.UNSAVED_DATA_WARNING, titleText: 'Are you sure?', message: "Are you sure you want to ".concat(userAction !== 'Create' ? 'update' : 'create', " the Dealer Program Association?"), acceptBtnText: 'Yes', denyBtnText: 'No', acceptCallBack: useCallback(function () {
                if (userAction !== 'Create') {
                    handleUpdateAdmin();
                }
                else {
                    handleCreateDealerProgramAssociation();
                }
                setOpenConfirmationPopup(false);
            }, [openConfirmationPopup]), denyCallBack: useCallback(function () {
                setOpenConfirmationPopup(false);
            }, []), open: openConfirmationPopup, key: "".concat(openConfirmationPopup), cancelCallBack: useCallback(function () {
                setOpenConfirmationPopup(false);
            }, []) }),
        React.createElement(ConfirmationAndInformationPoup, { popupType: POPUP_TYPE.SUCCESS_ALERT, titleText: 'Success!', message: "Dealer Program Association has been ".concat(userAction !== 'Create' ? 'updated' : 'created', " successfully."), acceptBtnText: 'Ok', acceptCallBack: useCallback(function () {
                setOpenSuccessPopup(false);
                handleDrawerClose();
                DealerProgramAssociationList();
            }, [openConfirmationPopup]), open: openSuccessPopup, key: "".concat(openSuccessPopup) }),
        React.createElement(Dialog, { open: open, className: 'MuiDialogue-upload location-popup' },
            React.createElement(DialogTitle, { sx: { m: 0, p: 2 }, id: "customized-dialog-title" }, actionType === 'View' ? 'View Dealer - Program' : "".concat(actionType, " Dealer - Program")),
            React.createElement(IconButton, { "aria-label": "close", onClick: function () { return handleDrawerClose(); }, className: 'popup-close', sx: {
                    position: 'absolute',
                    right: 8,
                    top: 8,
                } },
                React.createElement(CloseIcon, null)),
            React.createElement(DialogContent, { sx: { paddingTop: actionType === 'Edit' ? '0px' : '20px' } },
                actionType === 'Edit' &&
                    React.createElement(Box, { bgcolor: '#0e0e1333', borderRadius: '4px', padding: '4px 8px', marginBottom: '8px' },
                        React.createElement(Box, { sx: { cursor: 'pointer' }, display: 'flex', alignItems: 'center', justifyContent: isSyncing ? 'center' : 'flex-end', onClick: getSyncedData },
                            React.createElement(IconButton, { sx: { padding: '0px' } },
                                React.createElement(SyncOutlinedIcon, null)),
                            React.createElement(Typography, { padding: '0px 4px', fontSize: '14px' }, isSyncing ? 'Currently syncing the latest details from Aspire' : 'Sync again'))),
                actionType === 'View' ?
                    React.createElement(Grid, { container: true, rowSpacing: 3, columnSpacing: { xs: 1, sm: 2, md: 0 } },
                        React.createElement(Grid, { item: true, xs: 12, style: { borderBottom: '1px #E6E6E6 solid' } },
                            React.createElement(Grid, { style: { padding: '5px', borderBottom: 'light grey' } },
                                React.createElement(Typography, { className: 'view-page-labels' }, "Dealer OID"),
                                React.createElement(Typography, { className: 'view-page-values' }, rowData === null || rowData === void 0 ? void 0 : rowData.DealerAccountAspireOId))),
                        React.createElement(Grid, { item: true, xs: 12, style: { borderBottom: '1px #E6E6E6 solid' } },
                            React.createElement(Grid, { style: { padding: '5px', borderBottom: 'light grey' } },
                                React.createElement(Typography, { className: 'view-page-labels' }, "Dealer Name"),
                                React.createElement(Typography, { className: 'view-page-values' }, rowData === null || rowData === void 0 ? void 0 : rowData.DealerName))),
                        React.createElement(Grid, { item: true, xs: 12, style: { borderBottom: '1px #E6E6E6 solid' } },
                            React.createElement(Grid, { style: { padding: '5px', borderBottom: 'light grey' } },
                                React.createElement(Typography, { className: 'view-page-labels' }, "Programs"), (_c = (_b = rowData === null || rowData === void 0 ? void 0 : rowData.ProgramNames) === null || _b === void 0 ? void 0 : _b.split(',')) === null || _c === void 0 ? void 0 :
                                _c.map(function (program, index) { return (React.createElement(Typography, { className: 'view-page-values' }, "".concat(index + 1, ". ").concat(program))); }))))
                    :
                        React.createElement(Grid, { container: true, rowSpacing: 3, columnSpacing: { xs: 1, sm: 2, md: 3 } },
                            React.createElement(Grid, { item: true, xs: 12 },
                                React.createElement(TextField, { label: 'Dealer OID', name: 'DealerAccountAspireOId', isMandatoryField: true, placeholder: 'Dealer OID', fullWidth: true, disabled: userAction !== 'Create', value: dealerProgramInfo === null || dealerProgramInfo === void 0 ? void 0 : dealerProgramInfo.DealerAccountAspireOId, onChange: handleNewLocationInfoChange, onBlur: handleOnInputBlur, error: Boolean(errors.DealerAccountAspireOId), error_message: errors.DealerAccountAspireOId, type: "text" })),
                            React.createElement(Grid, { item: true, xs: 12 },
                                React.createElement(TextField, { label: 'Dealer Name', name: 'DealerName', isMandatoryField: true, placeholder: 'Dealer Name', fullWidth: true, disabled: userAction !== 'Create', value: dealerProgramInfo === null || dealerProgramInfo === void 0 ? void 0 : dealerProgramInfo.DealerName, onChange: handleNewLocationInfoChange, onBlur: handleOnInputBlur, error: Boolean(errors.DealerName), error_message: errors.DealerName, type: "text" })),
                            React.createElement(Grid, { item: true, xs: 12 },
                                React.createElement(AutoCompleteMultiSelectDD, { options: programsList, displayKey: 'label', valueKey: 'value', label: 'Programs', placeholder: 'Select', handleChange: handleSelectionChange, selectedValue: dealerProgramInfo === null || dealerProgramInfo === void 0 ? void 0 : dealerProgramInfo.ProgramNames, name: "ProgramNames", errorMessage: errors.ProgramNames, isMandatoryField: true, handleBlur: handleOnInputBlur })))),
            React.createElement(DialogActions, null,
                React.createElement(Button, { variant: 'outlined', onClick: function () { return handleDrawerClose(); }, size: 'small', className: 'btn-txt' }, "Cancel"),
                React.createElement(Button, { variant: 'contained', onClick: function () { return handleClick(); }, size: 'small', className: 'btn-txt' }, getButtonText())))));
};
export default DealerProgramAssocation;
