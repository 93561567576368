import { Box, Button, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { AddCircleOutlinedIcon } from '../icons';
import SearchField from '../FormComponents/SearchField';
import AutoCompleteDD from '../FormComponents/AutoCompleteDD';
import { api_service } from '../../api/api_service';
import { api_urls } from '../../api/api_urls';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
export var PORTAL_STATUS = [
    { status: 'All', code: 'all' },
    { status: "Pending", code: 0 },
    { status: "Approved", code: 1 },
    { status: "Active", code: "Active" },
    { status: 'Denied', code: 2 },
    { status: "Deactivated", code: "Deactivated" }
];
export var RESUBMIT_APPLICATION_STATUS = [
    { status: 'Processing', code: 1 },
    { status: 'Success', code: 2 },
    { status: 'ValidationsFailed', code: 3 },
    { status: 'ContactSyncFailed', code: 4 },
    { status: 'CustomerSyncFailed', code: 5 },
    { status: 'GuarantorSyncFailed', code: 6 },
    { status: 'AssetSyncFailed', code: 7 },
    { status: 'ContractSyncFailed', code: 8 },
    { status: 'UploadFilesSyncFailed', code: 9 },
    { status: 'MoveToAssetSyncFailed', code: 10 },
    { status: 'POFTSyncFailed', code: 11 }
];
var ModuleHeader = function (_a) {
    var moduleName = _a.moduleName, placeHolderText = _a.placeHolderText, hasPortalDD = _a.hasPortalDD, hasStatusDD = _a.hasStatusDD, hasSearch = _a.hasSearch, hasAddItem = _a.hasAddItem, selectedPortal = _a.selectedPortal, getSelectedPortal = _a.getSelectedPortal, selectedStatus = _a.selectedStatus, getSearchedText = _a.getSearchedText, isDrawerOpen = _a.isDrawerOpen, getVendorList = _a.getVendorList, resetDealerFilters = _a.resetDealerFilters, hasPortalCreate = _a.hasPortalCreate, handleCreatePortal = _a.handleCreatePortal, feildWidth = _a.feildWidth, hasAddItemName = _a.hasAddItemName;
    var _b = useState(undefined), vendorList = _b[0], setVendorList = _b[1];
    var _c = useState(moduleName == 'Resubmit Application' || moduleName == 'Dealer Program Association' ? { Id: 'all', Name: 'All' } : { Id: '', Name: '' }), selectedVendor = _c[0], setSelectedVendor = _c[1];
    var _d = useState({ status: '', code: '' }), selectedStatusItem = _d[0], setSelectedStatusItem = _d[1];
    var _e = useState(), searchText = _e[0], setSearchText = _e[1];
    useEffect(function () {
        if (hasPortalDD) {
            api_service.get({
                endPoint: api_urls.VENDOR_LIST,
            }).then(function (res) {
                if ((res === null || res === void 0 ? void 0 : res.data) && res.data.statusCode === 200 && getVendorList) {
                    var vendorOptions = res.data.data;
                    if (moduleName == 'Resubmit Application' || moduleName == 'Dealer Program Association') {
                        vendorOptions.unshift({ Id: 'all', Name: 'All' });
                    }
                    setVendorList(vendorOptions);
                    getVendorList(vendorOptions);
                }
            }).catch(function (err) { });
        }
    }, []);
    var handlePortalSelection = function (e) {
        var _a = e.target, name = _a.name, value = _a.value;
        var vendor = vendorList === null || vendorList === void 0 ? void 0 : vendorList.find(function (item) { return item.Id === value; });
        if (getSelectedPortal) {
            getSelectedPortal(value);
        }
        setSelectedVendor({ Id: value, Name: vendor === null || vendor === void 0 ? void 0 : vendor.Name });
    };
    var handleStatusSelection = function (e) {
        var _a = e.target, name = _a.name, value = _a.value;
        if (selectedStatus) {
            selectedStatus(value);
        }
        var selectedItem = PORTAL_STATUS.find(function (item) { return item.code === value; });
        console.log('selectedItem:', selectedItem);
        setSelectedStatusItem({ status: selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.status, code: selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.code });
    };
    var onSearchComplete = function (value) {
        if ((value && (value === null || value === void 0 ? void 0 : value.length) > 0) || value === '') {
            setSearchText(value);
            if (getSearchedText) {
                getSearchedText(value);
            }
        }
    };
    useEffect(function () {
        setSelectedStatusItem({ status: '', code: '' });
        setSearchText('');
    }, [resetDealerFilters]);
    var dealerValue = hasAddItemName === 'Add Dealer - Program';
    return (React.createElement(Grid, { container: true, columns: { xs: 4, sm: 12, md: 12 }, className: 'module-header-wrapper', bgcolor: '#F3F4F6', p: '7px 16px', pt: '15px', alignItems: 'center', minHeight: '50px' },
        React.createElement(Grid, { item: true, xs: 12, md: 4 },
            React.createElement(Typography, { fontSize: '18px' }, moduleName)),
        React.createElement(Grid, { item: true, xs: 11, md: 8 },
            React.createElement(Grid, { container: true, columns: { xs: 4, sm: 8, md: 12 }, justifyContent: { xs: 'center', sm: 'start', md: 'end', lg: 'end' }, gap: '10px', alignItems: 'flex-start', flexWrap: dealerValue ? 'nowrap' : 'wrap' },
                hasPortalDD &&
                    React.createElement(Grid, { item: true, xs: 12, sm: 3.5, md: 3.5 },
                        React.createElement(AutoCompleteDD, { options: vendorList !== null && vendorList !== void 0 ? vendorList : [], displayKey: 'Name', valueKey: 'Id', label: '', placeholder: 'SELECT PORTAL NAME', handleChange: handlePortalSelection, selectedValue: selectedVendor, name: "CustState", handleBlur: function () { } })),
                hasStatusDD &&
                    React.createElement(Grid, { item: true, xs: 12, sm: 3, md: 2.5 },
                        React.createElement(AutoCompleteDD, { options: PORTAL_STATUS, displayKey: 'status', valueKey: 'code', label: '', placeholder: 'Status', handleChange: handleStatusSelection, selectedValue: selectedStatusItem, name: "CustState", handleBlur: function () { }, showStartIcon: true, startIcon: React.createElement(FilterAltOutlinedIcon, null) })),
                hasSearch &&
                    React.createElement(Grid, { item: true, xs: 12, sm: 3, md: feildWidth || 2.5 },
                        React.createElement(SearchField, { value: searchText, onSearch: onSearchComplete, id: "outlined-basic", variant: "outlined", size: 'small', placeholder: placeHolderText !== null && placeHolderText !== void 0 ? placeHolderText : 'Search' })),
                hasAddItem &&
                    React.createElement(Grid, { item: true, xs: 12, sm: 4, md: 2.5, minWidth: dealerValue ? '210px' : '130px' },
                        React.createElement(Box, { sx: { display: 'flex', justifyContent: { xs: 'start', md: 'center' } } },
                            React.createElement(Button, { variant: 'contained', startIcon: React.createElement(AddCircleOutlinedIcon, null), size: 'small', onClick: function () { return isDrawerOpen ? isDrawerOpen(true) : ''; }, sx: { padding: '6px 10px', whiteSpace: 'nowrap' } },
                                React.createElement(Typography, { fontSize: '16px' }, hasAddItemName)))),
                hasPortalCreate &&
                    React.createElement(Button, { variant: 'contained', startIcon: React.createElement(AddCircleOutlineRoundedIcon, null), onClick: function () { handleCreatePortal && handleCreatePortal(); } }, "Create New Portal")))));
};
export default ModuleHeader;
