import React, { useCallback, useEffect, useState } from 'react';
import ModuleHeader from '../../components/ModuleHeader/ModuleHeader';
import ModuleTable from '../../components/UtilityComponents/moduleTable';
import { ProgramManagementTable } from '../../config/TableColumns';
import { Box } from '@mui/material';
import moment from 'moment';
import { api_service } from '../../api/api_service';
import { api_urls } from '../../api/api_urls';
import { useSelector } from 'react-redux';
import ProgramManagementDrawer from './ProgramManagementDrawer';
import { utility } from '../../utils';
import ConfirmationAndInformationPoup, { POPUP_TYPE } from '../../components/UtilityComponents/ConfirmationAndInformationPoup';
var ProgramManagement = function () {
    var userBasicInfo = useSelector(function (store) { return store.User.userData; });
    var _a = useState(), searchText = _a[0], setSearchText = _a[1];
    var _b = useState([]), programList = _b[0], setProgramList = _b[1];
    var _c = useState([]), freezedList = _c[0], setFreezedList = _c[1];
    var _d = useState(false), isFetching = _d[0], setIsFetching = _d[1];
    var _e = useState(), rowData = _e[0], setRowData = _e[1];
    var _f = useState(false), open = _f[0], setOpen = _f[1];
    var _g = useState(''), userAction = _g[0], setUserAction = _g[1];
    var _h = useState(null), selectedProgramIdToDelete = _h[0], setSelectedProgramIdToDelete = _h[1];
    var _j = useState(false), deleteConfirmationOpen = _j[0], setDeleteConfirmationOpen = _j[1];
    var getProgramList = function () {
        setIsFetching(true);
        setProgramList([]);
        api_service.get({
            endPoint: api_urls.PROGRAM_MANAGEMENT_LIST
        }).then(function (res) {
            setIsFetching(false);
            if ((res === null || res === void 0 ? void 0 : res.data) && res.data.statusCode === 200) {
                var __programList = res.data.data;
                __programList.map(function (eachDealer, index) {
                    eachDealer.CreatedTime = moment(eachDealer.CreatedTime).tz('America/New_York');
                });
                setProgramList(__programList);
                setFreezedList(__programList);
            }
        }).catch(function (err) { setIsFetching(false); });
    };
    var tableActionHandler = function (rowData, actionType) {
        console.log('rowData:', rowData);
        console.log('actionType:', actionType);
        if (actionType == 'Edit') {
            setRowData(rowData);
            setUserAction('Edit');
            setOpen(true);
        }
        else if (actionType == 'Delete') {
            setSelectedProgramIdToDelete(rowData === null || rowData === void 0 ? void 0 : rowData.Id);
            setDeleteConfirmationOpen(true);
        }
    };
    var handleDeleteProgram = function () {
        api_service.delete({
            endPoint: "".concat(api_urls.DELETE_PROGRAM, "?ProgramId=").concat(selectedProgramIdToDelete),
        }).then(function (res) {
            var _a;
            if (res && res.data.statusCode === 200) {
                setSelectedProgramIdToDelete('');
                getProgramList();
            }
            else {
                utility.alert((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.message);
            }
        }).catch(function (err) {
            console.log("error in handleDeleteNotifications ");
        });
    };
    var handleViewDetails = function (__rowData) {
        console.log('handleViewDetails ~ rowData:', __rowData);
        setRowData(__rowData);
        setUserAction('View');
        setOpen(true);
    };
    var handleCreateAdmin = function () {
        setUserAction('Create');
        setOpen(true);
    };
    var handleClose = function () {
        setUserAction('');
        setRowData(null);
        setOpen(false);
    };
    useEffect(function () {
        var searchTerm = searchText === null || searchText === void 0 ? void 0 : searchText.toLowerCase();
        if (searchTerm === '') {
            setProgramList(freezedList);
        }
        else {
            var filteredResults = freezedList.filter(function (item) { var _a; return (_a = item.Name) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(searchTerm); });
            setProgramList(filteredResults);
        }
    }, [searchText]);
    useEffect(function () {
        getProgramList();
    }, []);
    return (React.createElement(Box, { className: "common-module-wrapper user-management-wrapper" },
        React.createElement(ModuleHeader, { moduleName: 'Program Management', placeHolderText: 'Search by Program Name', hasPortalDD: false, hasStatusDD: false, hasSearch: true, hasAddItem: true, hasAddItemName: 'Add Program', getSelectedPortal: function () { }, selectedStatus: function () { }, getSearchedText: setSearchText, isDrawerOpen: function () { return handleCreateAdmin(); }, feildWidth: 3.5 }),
        React.createElement(Box, { className: "module-common-table-wrapper" },
            React.createElement(ModuleTable, { dataToDisplay: programList, columns: ProgramManagementTable, isLoading: isFetching, actionHandler: tableActionHandler, onClickHandler: handleViewDetails, isRowClickEnabled: true, moduleName: "Program Management", isActionColumn: true, hasDelete: true })),
        React.createElement(Box, null,
            React.createElement(ProgramManagementDrawer, { open: open, handleClose: handleClose, rowData: rowData, userAction: userAction, getProgramList: getProgramList })),
        React.createElement(ConfirmationAndInformationPoup, { popupType: POPUP_TYPE.DELETE_CONFIRMATION_WARNING, titleText: 'Are you sure?', message: 'Are you sure you want to delete this Program?', acceptBtnText: 'Yes', denyBtnText: 'No', acceptCallBack: useCallback(function () {
                console.log('acceptCallBack');
                handleDeleteProgram();
                setDeleteConfirmationOpen(false);
            }, [deleteConfirmationOpen]), denyCallBack: useCallback(function () {
                setSelectedProgramIdToDelete('');
                setDeleteConfirmationOpen(false);
            }, []), open: deleteConfirmationOpen, key: "".concat(deleteConfirmationOpen), cancelCallBack: useCallback(function () {
                setSelectedProgramIdToDelete('');
                setDeleteConfirmationOpen(false);
            }, []) })));
};
export default ProgramManagement;
