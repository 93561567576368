export var NavigationConfig = {
    Account: {
        Signup: '/signup',
        Login: '/login',
        Forgot_Password: '/forgotpassword',
        Reset_Password: '/resetpassword',
        Email_Confirmation: '/emailconfirmation',
        Verify_Success: '/verifysuccess',
        NeedHelp: '/needhelp',
        SignUP_OTP: '/signup_otp',
        Forgot_Pwd_OTP: '/forgot_pwd_otp',
    },
    Dashboard: '/dashboard',
    UserManagement: '/userManagement',
    DealerManagement: '/dealerManagement',
    DealerProgramAssocation: '/dealerProgramAssociation',
    AdminManagement: '/adminManagement',
    ResubmitApplication: '/resubmitApplication',
    Support: '/support',
    PageNotFound: '/*',
    MarketingConent: '/marketingContent',
    ProgramManagement: '/ProgramManagement',
};
