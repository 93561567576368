import { Stack, Button, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
var PageNotFound = function () {
    var navigate = useNavigate();
    return (React.createElement(Stack, { my: '1rem', justifyContent: 'center', flexDirection: 'row', alignItems: 'center' },
        React.createElement(Typography, null, "PageNotFound :("),
        React.createElement(Button, { sx: { margin: '0 1rem' }, variant: 'contained', className: 'btn-txt', onClick: function () { return navigate(-1); } }, "Go Back")));
};
export default PageNotFound;
