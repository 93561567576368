var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Typography, IconButton, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Pagination, Box, Select, MenuItem, Stack, tableCellClasses, Popover, Divider } from '@mui/material';
import { SortingTableIcon, UserApproveIcon, UserDenyIcon, UserDeactivateIcon, MoreActionIcon, EditPencilIcon, ResubmitApplicationIcon } from '../icons';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { styled } from '@mui/material/styles';
import { PORTAL_STATUS, RESUBMIT_APPLICATION_STATUS } from '../ModuleHeader/ModuleHeader';
import './../../assets/scss/moduleTable.scss';
import { AdminMangementDataEnum, DealerMangementDataEnum, MarketingContentEnum, ResubmitApplicationDataEnum, SupportTicketDataEnum, UserMangementDataEnum } from '../../config/TableColumns';
import { formatPhoneNumber } from '../../utils/commonUtilities';
import DeleteIcon from '../icons/DeleteIcon';
import ReplayIcon from '@mui/icons-material/Replay';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
var BasicPopover = function (_a) {
    var rowData = _a.rowData, tooltipActionHandler = _a.tooltipActionHandler, moduleType = _a.moduleType, scrollCounter = _a.scrollCounter;
    var _b = React.useState(null), anchorEl = _b[0], setAnchorEl = _b[1];
    var handleClick = function (event) {
        setAnchorEl(event.currentTarget);
    };
    var handleClose = function () {
        setAnchorEl(null);
    };
    var open = Boolean(anchorEl);
    var id = open ? 'simple-popover' : undefined;
    return (React.createElement("div", null,
        React.createElement(IconButton, { onClick: handleClick, sx: {
                color: { opacity: (rowData === null || rowData === void 0 ? void 0 : rowData.Status) == 0 || (rowData === null || rowData === void 0 ? void 0 : rowData.Status) == 2 ? 0.5 : 1 },
                pointerEvents: rowData.Status == 0 || (rowData === null || rowData === void 0 ? void 0 : rowData.Status) == 2 ? 'none' : 'auto',
            } },
            React.createElement(MoreActionIcon, null)),
        React.createElement(Popover, { id: id, open: open, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
            }, transformOrigin: {
                vertical: 'top',
                horizontal: 'center',
            } },
            moduleType === "User Management" &&
                React.createElement(Stack, null,
                    (rowData.userStatus === 0 || rowData.userStatus === 2) && rowData.RoleType !== "VENDSREP" && rowData.RoleType !== "CUSTOMER" &&
                        React.createElement(Stack, { direction: 'row', onClick: function () {
                                tooltipActionHandler(rowData, "Approved");
                            }, sx: { cursor: 'pointer' }, alignItems: 'center' },
                            React.createElement(IconButton, null,
                                React.createElement(UserApproveIcon, null)),
                            React.createElement(Typography, { color: '#000000', fontSize: '14px', paddingRight: '8px' }, "Approve")),
                    (rowData.userStatus === 0 || rowData.userStatus === 1) &&
                        rowData.RoleType !== "VENDSREP" && rowData.RoleType !== "CUSTOMER" &&
                        rowData.userStatus !== 2 &&
                        React.createElement(Stack, { direction: 'row', onClick: function () {
                                tooltipActionHandler(rowData, "Denied");
                            }, sx: { cursor: 'pointer' }, alignItems: 'center' },
                            React.createElement(IconButton, null,
                                React.createElement(UserDenyIcon, null)),
                            React.createElement(Typography, { color: '#000000', fontSize: '14px', paddingRight: '8px' }, "Deny")),
                    rowData.userStatus !== "Deactivated" &&
                        React.createElement(Stack, { direction: 'row', onClick: function () {
                                tooltipActionHandler(rowData, "Deactivated");
                            }, sx: { cursor: 'pointer' }, alignItems: 'center' },
                            React.createElement(IconButton, null,
                                React.createElement(UserDeactivateIcon, null)),
                            React.createElement(Typography, { color: '#000000', fontSize: '14px', paddingRight: '8px' }, "Deactivate User")),
                    rowData.userStatus !== "Deactivated" &&
                        React.createElement(Stack, { direction: 'row', onClick: function () {
                                tooltipActionHandler(rowData, 'impersonate');
                            }, sx: { cursor: 'pointer' }, alignItems: 'center' },
                            React.createElement(IconButton, { sx: { width: '36px' } },
                                React.createElement(SupervisorAccountIcon, { sx: { color: '#363948' } })),
                            React.createElement(Typography, { color: '#000000', fontSize: '14px', paddingRight: '8px' }, "Impersonate"))),
            moduleType === "Marketing Content" &&
                React.createElement(Stack, { p: '2px 8px 0px 4px' }, rowData.Status !== 3 ?
                    (React.createElement(React.Fragment, null,
                        React.createElement(Stack, { direction: 'row', onClick: function () {
                                tooltipActionHandler(rowData, "EDIT");
                                setAnchorEl(null);
                            }, sx: { cursor: 'pointer' }, alignItems: 'center' },
                            React.createElement(IconButton, { size: 'small' },
                                React.createElement(EditPencilIcon, null)),
                            React.createElement(Typography, { color: '#000000', fontSize: '14px' }, "\u00A0\u00A0Edit")),
                        React.createElement(Divider, null),
                        React.createElement(Stack, { direction: 'row', onClick: function () {
                                tooltipActionHandler(rowData, "DELETE");
                                setAnchorEl(null);
                            }, sx: { cursor: 'pointer' }, alignItems: 'center' },
                            React.createElement(IconButton, { size: 'small' },
                                React.createElement(DeleteIcon, null)),
                            React.createElement(Typography, { color: '#000000', fontSize: '14px' }, "Delete")))) :
                    (React.createElement(React.Fragment, null,
                        React.createElement(Divider, null),
                        React.createElement(Stack, { direction: 'row', onClick: function () {
                                tooltipActionHandler(rowData, "EDIT");
                                setAnchorEl(null);
                            }, sx: { cursor: 'pointer' }, alignItems: 'center' },
                            React.createElement(IconButton, { size: 'small' },
                                React.createElement(ReplayIcon, null)),
                            React.createElement(Typography, { color: '#000000', fontSize: '14px' }, "Re Activate"))))),
            moduleType === "Dealer Program Association" &&
                React.createElement(Stack, { p: '2px 8px 0px 4px' },
                    React.createElement(Stack, { direction: 'row', sx: { cursor: 'pointer' }, alignItems: 'center', onClick: function () {
                            tooltipActionHandler(rowData, 'Edit');
                            setAnchorEl(null);
                        } },
                        React.createElement(IconButton, { size: 'small' },
                            React.createElement(EditPencilIcon, null)),
                        React.createElement(Typography, { color: '#000000', fontSize: '14px' }, "\u00A0\u00A0Edit")),
                    React.createElement(Divider, null),
                    React.createElement(Stack, { direction: 'row', sx: { cursor: 'pointer' }, alignItems: 'center', onClick: function () {
                            tooltipActionHandler(rowData, 'Delete');
                            setAnchorEl(null);
                        } },
                        React.createElement(IconButton, { size: 'small' },
                            React.createElement(DeleteIcon, null)),
                        React.createElement(Typography, { color: '#000000', fontSize: '14px' }, "Delete"))),
            moduleType === "Program Management" &&
                React.createElement(Stack, { p: '2px 8px 0px 4px' },
                    React.createElement(Stack, { direction: 'row', sx: { cursor: 'pointer' }, alignItems: 'center', onClick: function () {
                            tooltipActionHandler(rowData, 'Edit');
                            setAnchorEl(null);
                        } },
                        React.createElement(IconButton, { size: 'small' },
                            React.createElement(EditPencilIcon, null)),
                        React.createElement(Typography, { color: '#000000', fontSize: '14px' }, "\u00A0\u00A0Edit")),
                    React.createElement(Divider, null),
                    React.createElement(Stack, { direction: 'row', sx: { cursor: 'pointer' }, alignItems: 'center', onClick: function () {
                            tooltipActionHandler(rowData, 'Delete');
                            setAnchorEl(null);
                        } },
                        React.createElement(IconButton, { size: 'small' },
                            React.createElement(DeleteIcon, null)),
                        React.createElement(Typography, { color: '#000000', fontSize: '14px' }, "Delete"))),
            moduleType === "Resubmit Application" &&
                React.createElement(Stack, { p: '2px 8px 0px 4px' },
                    React.createElement(Stack, { direction: 'row', onClick: function () {
                            tooltipActionHandler(rowData, 'Resubmit');
                            setAnchorEl(null);
                        }, sx: { cursor: 'pointer' }, alignItems: 'center' },
                        React.createElement(IconButton, { size: 'small' },
                            React.createElement(ResubmitApplicationIcon, { isFromSideMenu: false })),
                        React.createElement(Typography, { color: '#000000', fontSize: '14px' }, "\u00A0\u00A0Resubmit")),
                    React.createElement(Divider, null),
                    React.createElement(Stack, { direction: 'row', onClick: function () {
                            tooltipActionHandler(rowData, 'Delete');
                            setAnchorEl(null);
                        }, sx: { cursor: 'pointer' }, alignItems: 'center' },
                        React.createElement(IconButton, { size: 'small' },
                            React.createElement(DeleteIcon, null)),
                        React.createElement(Typography, { color: '#000000', fontSize: '14px' }, "Delete"))))));
};
var StyledTableCell = styled(TableCell)(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {},
        _b["&.".concat(tableCellClasses.head)] = {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
            padding: '0px 16px',
            height: '40px'
        },
        _b["&.".concat(tableCellClasses.body)] = {
            fontSize: 14,
        },
        _b);
});
var SORT_ORDER;
(function (SORT_ORDER) {
    SORT_ORDER["ASC"] = "ASC";
    SORT_ORDER["DESC"] = "DESC";
})(SORT_ORDER || (SORT_ORDER = {}));
var sortColumnName = '';
var sortOrder = SORT_ORDER.ASC;
var ModuleTable = function (_a) {
    var columns = _a.columns, dataToDisplay = _a.dataToDisplay, isLoading = _a.isLoading, isActionColumn = _a.isActionColumn, actionHandler = _a.actionHandler, moduleName = _a.moduleName, isRowClickEnabled = _a.isRowClickEnabled, onClickHandler = _a.onClickHandler, isPortalSelected = _a.isPortalSelected, hasDelete = _a.hasDelete, rowDeleteClick = _a.rowDeleteClick;
    var _b = useState(dataToDisplay !== null && dataToDisplay !== void 0 ? dataToDisplay : []), tableData = _b[0], setTableData = _b[1];
    var _c = useState(10), perPageCount = _c[0], setPerPageCount = _c[1];
    var _d = useState(1), currentPage = _d[0], setCurrentPage = _d[1];
    var _e = useState(0), scrollCounter = _e[0], setScrollCounter = _e[1];
    var getResubmitStatusColor = function (statusCode) {
        switch (statusCode) {
            case 1:
                return '#1C64F2';
            case 2:
                return '#FF5A1F';
            default:
                return '#057A55';
        }
    };
    var getResubmitAppSyncStatus = function (appStatus) {
        switch (appStatus) {
            case '1':
                return 'Processing';
            case '2':
                return 'Success';
            default:
                return 'Failed';
        }
    };
    var getRowData = function (index, fieldName, value, row) {
        var _a;
        if (row === void 0) { row = ''; }
        switch (fieldName) {
            case ResubmitApplicationDataEnum.DateAndTime: {
                return React.createElement("span", { key: "".concat(fieldName, "-").concat(index), className: 'txt-elipsis' }, value ? moment(value).format('MM/DD/YYYY | hh:mm A') : '--');
            }
            case UserMangementDataEnum.Name: {
                return React.createElement("span", { key: "".concat(fieldName, "-").concat(index), className: 'txt-elipsis' }, value ? "".concat(row.FirstName, " ").concat(row.LastName) : '--');
            }
            case ResubmitApplicationDataEnum.Status: {
                if (value !== undefined || value !== null) {
                    if ((_a = RESUBMIT_APPLICATION_STATUS.find(function (obj) { return obj.code == value; })) === null || _a === void 0 ? void 0 : _a.status) {
                        return (React.createElement("span", { key: "".concat(fieldName, "-").concat(index), className: 'txt-elipsis' },
                            React.createElement(Typography, { fontWeight: 600, color: getResubmitStatusColor(value) }, getResubmitAppSyncStatus(value))));
                    }
                }
                return React.createElement("span", { key: "".concat(fieldName, "-").concat(index), className: 'txt-elipsis' }, '--');
            }
            case UserMangementDataEnum.CreatedTime: {
                return React.createElement("span", { key: "".concat(fieldName, "-").concat(index), className: 'txt-elipsis' }, value === null || value === void 0 ? void 0 : value.format('MM/DD/YYYY | hh:mm A'));
            }
            case UserMangementDataEnum.Status: {
                if (value !== undefined || value !== null) {
                    if (value === PORTAL_STATUS[3].code) {
                        return (React.createElement("span", { key: "".concat(fieldName, "-").concat(index), className: 'txt-elipsis' },
                            React.createElement(Typography, { fontWeight: 600, color: '#1C64F2' }, "Active")));
                    }
                    else if (value === PORTAL_STATUS[1].code) {
                        return React.createElement("span", { key: "".concat(fieldName, "-").concat(index), className: 'txt-elipsis' },
                            React.createElement(Typography, { fontWeight: 600, color: '#FF5A1F' }, "Pending"));
                    }
                    else if (value === PORTAL_STATUS[2].code) {
                        return React.createElement("span", { key: "".concat(fieldName, "-").concat(index), className: 'txt-elipsis' },
                            React.createElement(Typography, { fontWeight: 600, color: '#057A55' }, "Approved"));
                    }
                    else if (value === PORTAL_STATUS[4].code) {
                        return React.createElement("span", { key: "".concat(fieldName, "-").concat(index), className: 'txt-elipsis' },
                            React.createElement(Typography, { fontWeight: 600, color: '#FF5A1F' }, "Denied"));
                    }
                    else if (value === "Deactivated") {
                        return (React.createElement("span", { key: "".concat(fieldName, "-").concat(index), className: 'txt-elipsis' },
                            React.createElement(Typography, { fontWeight: 600, color: '#FF5A1F' }, "Deactivated")));
                    }
                }
                return React.createElement("span", { key: "".concat(fieldName, "-").concat(index), className: 'txt-elipsis' }, '--');
            }
            case SupportTicketDataEnum.TicketId: {
                return (React.createElement("span", { key: "".concat(fieldName, "-").concat(index), className: 'txt-elipsis' },
                    React.createElement(Typography, { fontWeight: 600, color: '#1C64F2' }, value || '--')));
            }
            case AdminMangementDataEnum.PhoneNumber: {
                return React.createElement("span", { key: "".concat(fieldName, "-").concat(index), title: value ? formatPhoneNumber(value) : '', className: 'txt-elipsis' }, value ? formatPhoneNumber(value) : '--');
            }
            case DealerMangementDataEnum.PortalName: {
                return (React.createElement("span", { key: "".concat(fieldName, "-").concat(index), className: 'txt-elipsis' }, moduleName === "Portal Management" && value ? React.createElement("a", { href: value, target: '_blank', rel: 'noopener' }, value) : React.createElement(React.Fragment, null, value || '--')));
            }
            case MarketingContentEnum.Status: {
                if (value !== undefined || value !== null) {
                    if (value == 1) {
                        return (React.createElement("span", { key: "".concat(fieldName, "-").concat(index), className: 'txt-elipsis' },
                            React.createElement(Typography, { fontWeight: 600, color: '#057A55' }, "Live")));
                    }
                    else if (value == 2) {
                        return React.createElement("span", { key: "".concat(fieldName, "-").concat(index), className: 'txt-elipsis' },
                            React.createElement(Typography, { fontWeight: 600, color: '#EB4B4B' }, "Withdrawn"));
                    }
                    else if (value == 3) {
                        return React.createElement("span", { key: "".concat(fieldName, "-").concat(index), className: 'txt-elipsis' },
                            React.createElement(Typography, { fontWeight: 600, color: '#EB4B4B' }, "Expired"));
                    }
                    else if (value == 4) {
                        return React.createElement("span", { key: "".concat(fieldName, "-").concat(index), className: 'txt-elipsis' },
                            React.createElement(Typography, { fontWeight: 600, color: '#057A55' }, "Scheduled"));
                    }
                }
                return React.createElement("span", { key: "".concat(fieldName, "-").concat(index), className: 'txt-elipsis' }, '--');
            }
            default: {
                return React.createElement("span", { key: "".concat(fieldName, "-").concat(index), title: value || '', className: 'txt-elipsis' }, value || '--');
            }
        }
    };
    var handleSort = function (sortColKey, colDataType) {
        if (sortOrder === SORT_ORDER.ASC) {
            sortOrder = SORT_ORDER.DESC;
        }
        else {
            sortOrder = SORT_ORDER.ASC;
        }
        if (sortColumnName !== sortColKey) {
            sortColumnName = sortColKey;
            sortOrder = SORT_ORDER.ASC;
        }
        var data = __spreadArray([], tableData, true);
        switch (colDataType) {
            case 'string': {
                data.sort(function (a, b) {
                    var nameA = a[sortColKey] ? a[sortColKey].toUpperCase() : '';
                    var nameB = b[sortColKey] ? b[sortColKey].toUpperCase() : '';
                    if (sortOrder === SORT_ORDER.ASC) {
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }
                    }
                    else {
                        if (nameA < nameB) {
                            return 1;
                        }
                        if (nameA > nameB) {
                            return -1;
                        }
                    }
                    return 0;
                });
                break;
            }
            case 'number': {
                if (sortOrder === SORT_ORDER.ASC) {
                    data.sort(function (firstItem, secondItem) { return firstItem[sortColKey] - secondItem[sortColKey]; });
                }
                else {
                    data.sort(function (firstItem, secondItem) { return secondItem[sortColKey] - firstItem[sortColKey]; });
                }
                break;
            }
            case 'date': {
                if (sortOrder === SORT_ORDER.ASC) {
                    data.sort(function (firstItem, secondItem) {
                        return moment.utc(firstItem[sortColKey]).diff(moment.utc(secondItem[sortColKey]));
                    });
                }
                else {
                    data.sort(function (firstItem, secondItem) {
                        return moment.utc(secondItem[sortColKey]).diff(moment.utc(firstItem[sortColKey]));
                    });
                }
                break;
            }
        }
        setTableData(data);
    };
    useEffect(function () {
        if (dataToDisplay) {
            setTableData(dataToDisplay);
            setCurrentPage(1);
        }
    }, [dataToDisplay]);
    var getTableNoDataText = function () {
        if (isLoading) {
            return 'Loading...';
        }
        if (isPortalSelected === null) {
            return 'Please Select Portal Name';
        }
        return 'No Data';
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(TableContainer, { component: Paper, className: 'table-component', onScroll: function () { return setScrollCounter(function (prevState) { return prevState + 1; }); } },
            React.createElement(Table, { sx: { minWidth: 650, }, "aria-label": "simple table", stickyHeader: true },
                React.createElement(TableHead, null,
                    React.createElement(TableRow, null,
                        columns.map(function (col, index) {
                            return (React.createElement(StyledTableCell, { key: index, className: "txt-elipsis" },
                                col.label,
                                col.hasSort &&
                                    React.createElement(IconButton, { onClick: function () { return handleSort(col.apiKey, col.colDataType); } },
                                        React.createElement(SortingTableIcon, null))));
                        }),
                        isActionColumn !== false &&
                            React.createElement(StyledTableCell, null, "Actions"))),
                React.createElement(TableBody, null, (tableData === null || tableData === void 0 ? void 0 : tableData.length) ? (tableData.filter(function (item, index) {
                    var min = (perPageCount * currentPage) - perPageCount;
                    var max = (perPageCount * currentPage);
                    if (index >= min && index < max) {
                        return item;
                    }
                }))
                    .map(function (row, idx) {
                    return (React.createElement(TableRow, { key: idx, style: { cursor: 'pointer' } },
                        React.createElement(React.Fragment, null,
                            columns.map(function (col, index) {
                                return (React.createElement(StyledTableCell, { key: index, onClick: function () { return (onClickHandler && isRowClickEnabled) && onClickHandler(row); } }, getRowData(index, col.apiKey, row[col.apiKey], row)));
                            }),
                            isActionColumn !== false &&
                                React.createElement(StyledTableCell, { className: "action-col" },
                                    React.createElement(BasicPopover, { rowData: row, tooltipActionHandler: actionHandler, moduleType: moduleName, scrollCounter: scrollCounter })))));
                })
                    :
                        React.createElement(TableRow, { className: "no-data-text" },
                            React.createElement(StyledTableCell, { colSpan: columns.length + 1 },
                                React.createElement(Typography, { textAlign: 'center', fontSize: '16px', fontWeight: 600 }, getTableNoDataText())))))),
        (tableData === null || tableData === void 0 ? void 0 : tableData.length) > 0 &&
            React.createElement(Stack, { sx: { flexDirection: { xs: 'column', sm: 'row', md: 'row' } }, justifyContent: 'space-between', pb: '10px' },
                React.createElement(Box, null),
                React.createElement(Box, null,
                    React.createElement(Pagination, { count: (tableData.length / perPageCount) < 1 ? 1 : Math.ceil(tableData.length / perPageCount), shape: "rounded", showFirstButton: true, showLastButton: true, size: "small", onChange: function (event, value) { return setCurrentPage(value); }, key: tableData.length || perPageCount || currentPage, page: currentPage })),
                React.createElement(Stack, { direction: 'row' },
                    React.createElement(Typography, { fontSize: '16px' }, "Rows per page:\u00A0\u00A0\u00A0\u00A0"),
                    React.createElement(Select, { labelId: "demo-customized-select-label", size: 'small', id: "demo-customized-select", value: perPageCount, onChange: function (event) {
                            setPerPageCount(Number(event.target.value));
                            setCurrentPage(1);
                        }, variant: "standard" },
                        React.createElement(MenuItem, { value: 5 }, "5"),
                        React.createElement(MenuItem, { value: 10 }, "10"),
                        React.createElement(MenuItem, { value: 15 }, "15"))))));
};
export default React.memo(ModuleTable);
